<template>
  <b-button-group v-if="layout === 'group'">
    <b-button
      size="sm"
      :variant="tableLayout === 'table' ? 'secondary' : 'outline-secondary'"
      @click="() => setTableLayout('table')"
    >
      <font-awesome-icon
        :icon="['fas', 'table-list']"
      />
    </b-button>
    <b-button
      size="sm"
      :variant="tableLayout === 'card' ? 'secondary' : 'outline-secondary'"
      @click="() => setTableLayout('card')"
    >
      <font-awesome-icon
        :icon="['fas', 'table-cells']"
      />
    </b-button>
  </b-button-group>
  <b-dropdown-group
    v-else-if="layout === 'dropdown'"
    id="ddg-table-lt"
    :header="$t('eDt_lt_label')"
  >
    <b-dropdown-item-button
      :variant="tableLayout === 'table' ? 'primary' : 'muted'"
      @click="() => setTableLayout('table')"
    >
      <font-awesome-icon
        :icon="['fas', 'table-list']"
        class="mr-1"
      />
      {{ $t('eDt_lt_table') }}
    </b-dropdown-item-button>
    <b-dropdown-item-button
      :variant="tableLayout === 'card' ? 'primary' : 'muted'"
      @click="() => setTableLayout('card')"
    >
      <font-awesome-icon
        :icon="['fas', 'table-cells']"
        class="mr-1"
      />
      {{ $t('eDt_lt_cards') }}
    </b-dropdown-item-button>
  </b-dropdown-group>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DataLayoutSwitcher',
  props: {
    layout: {
      type: [String],
      default: 'group'
    }
  },
  computed: {
    ...mapGetters({
      deviceInfo: 'device',
      tableLayout: 'tableLayout'
    })
  },
  methods: {
    ...mapActions({
      setTableLayout: 'setTableLayout'
    })
  }
}
</script>

<style scoped>

</style>
