<template>
  <client-only>
    <b-collapse
      :visible="state"
      @shown="onShown"
      @hidden="onHidden"
    >
      <div
        v-click-outside="onClickOutside"
        class="search-wrapper"
      >
        <b-input-group class="w-100">
          <b-input-group-prepend>
            <b-button
              variant="outline-secondary"
              size="sm"
              @click="onSearch(q)"
            >
              <font-awesome-icon
                v-if="!isBusy"
                :icon="['fas', 'magnifying-glass']"
              />
              <font-awesome-icon
                v-else
                :icon="['fas', 'circle-notch']"
                spin
              />
            </b-button>
          </b-input-group-prepend>
          <b-form-input
            v-model="q"
            :placeholder="placeholder"
            class="search-input"
            size="sm"
            @input="onSearchQuery"
            @keyup.enter="onSearch(q)"
          />
          <b-input-group-append>
            <b-button
              v-if="q !== ''"
              variant="outline-danger"
              size="sm"
              @click="onClear"
            >
              <font-awesome-icon
                :icon="['fas', 'xmark']"
              />
            </b-button>
            <b-button
              v-if="closeBtn"
              variant="outline-secondary"
              size="sm"
              @click="onClose"
            >
              <font-awesome-icon :icon="['fas', 'chevron-up']" />
            </b-button>
            <b-button
              v-if="info.length"
              id="pop-info-target"
              variant="outline-secondary"
              size="sm"
              @click="onInfo"
            >
              <font-awesome-icon icon="circle-question" />
            </b-button>
            <b-popover
              target="pop-info-target"
              triggers="hover focus click"
              placement="top"
            >
              <template #title>
                {{ $t('eDt_s_info_title') }}
              </template>
              <div
                v-if="info.length === 1"
                v-shtml="line"
                class="mx-2 my-1"
              />
              <b-list-group
                v-else
                class="p-0 m-0"
              >
                <b-list-group-item
                  v-for="(line, i) in info"
                  :key="i"
                  v-shtml="line"
                />
              </b-list-group>
            </b-popover>
          </b-input-group-append>
        </b-input-group>
        <b-nav
          v-if="Object.keys(completeList).length"
          class="search-list w-100 px-0 py-0 border"
          style="max-height: 325px; overflow: hidden; overflow-y: auto;"
        >
          <slot name="autocomplete-item" :data="{ items: completeList, isCustom, onSearch }">
            <div
              v-for="(item, i) in completeList"
              :key="i"
              class="dropdown-item border-bottom px-2 w-100"
              @click="onSearch(isCustom ? item.value : item)"
            >
              <div
                v-shtml="isCustom ? item.preview : item"
                class="overflow-hidden text-truncate"
              />
            </div>
          </slot>
        </b-nav>
      </div>
    </b-collapse>
  </client-only>
</template>

<script>
import { debounce } from 'lodash'

export default {
  name: 'DataSearch',
  props: {
    value: [Boolean],
    query: {
      type: String,
      default: ''
    },
    list: {
      type: [Array, Object],
      default: () => ([])
    },
    isBusy: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: 'Search...'
    },
    closeBtn: {
      type: Boolean,
      default: false
    },
    info: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      state: this.value,
      q: this.query
    }
  },
  computed: {
    isCustom () {
      return this.list &&
        typeof this.list[0] === 'object' &&
        typeof this.list[0].value !== 'undefined' &&
        typeof this.list[0].preview !== 'undefined'
    },
    completeList () {
      return this.list
    }
  },
  watch: {
    value (n) {
      this.state = !!n
    },
    query (n) {
      this.q = unescape(n)
    }
  },
  methods: {
    onSearchQuery: debounce(function (q) {
      this.$emit('change-query', escape(q))
    }, 300),
    onSearch (query) {
      this.$emit('search', escape(query))
    },
    onClear () {
      this.q = ''
      this.$emit('search', '')
    },
    onClickOutside () {
      this.$emit('flush-results')
    },
    onShown (e) {
      this.$emit('on-shown', e)
    },
    onHidden (e) {
      this.$emit('on-hidden', e)
    },
    onClose () {
      this.$emit('on-close')
    },
    onInfo () {
      //
    }
  }
}
</script>

<style scoped>
.search-wrapper {
  position: relative;
}
.search-list {
  background: #ffffff;
  position: absolute;
  z-index: 5;
}
.dropdown-item, .dropdown-item > * {
  cursor: pointer;
}
</style>
