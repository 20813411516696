<template>
  <div
    v-if="noCollapseCount"
    class="filter-wrapper-inline"
    :class="{
      'd-inline-flex flex-wrap':isDesktop
    }"
    :style="isDesktop ? ' ' : 'display: contents;'"
  >
    <b-form-group
      v-for="field in noCollapseFields"
      :id="field.key"
      :key="field.key"
      class="mb-2 mb-lg-0"
      :class="isMobile ? 'w-50 px-1' : 'mr-1'"
    >
      <b-input-group
        :prepend="field.label"
        size="sm"
      >
        <template #append>
          <b-button
            :variant="rawFilter[field.key] === '' ? 'outline-secondary' : 'outline-danger'"
            :disabled="rawFilter[field.key] === ''"
            @click="() => { onClearItem(field); }"
          >
            <font-awesome-icon icon="xmark" />
          </b-button>
        </template>
        <b-form-select
          v-if="isSelectField(field)"
          :id="`filter-input-${field.key}`"
          v-model="rawFilter[field.key]"
          :options="filterData[field.key]"
          label-field="value"
          value-field="id"
          size="sm"
          :class="getInputCls(field.key)"
          @change="() => { onSubmit(); }"
        />
        <b-form-datepicker
          v-else-if="isDateField(field)"
          :id="`filter-input-${field.key}`"
          v-model="rawFilter[field.key]"
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          size="sm"
          locale="en-GB"
          :class="getInputCls(field.key)"
          @change="() => { onSubmit(); }"
        />
        <date-range
          v-else-if="isDateRangeField(field)"
          :id="`filter-input-${field.key}`"
          v-model="rawFilter[`${field.key}`]"
          :variant="getInputVariant(field.key)"
          @change="() => { onSubmit(); }"
        />
        <b-form-checkbox
          v-else-if="isCheckBoxField(field)"
          :id="`filter-checkbox-${field.key}`"
          v-model="rawFilter[field.key]"
          :class="getInputCls(field.key)"
          @change="() => { onSubmit(); }"
        />
        <b-form-input
          v-else
          :id="`filter-input-${field.key}`"
          v-model="rawFilter[field.key]"
          trim
          size="sm"
          :class="getInputCls(field.key)"
          @keyup.enter="() => { onSubmit(); }"
          @change="() => { onSubmit(); }"
        />
      </b-input-group>
    </b-form-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import fields from '~/mixins/fields'

export default {
  name: 'DataFilterInline',
  props: {
    fields: {
      type: Array,
      default: () => ([])
    },
    filter: {
      type: Array,
      default: () => ([])
    },
    filterData: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      rawFilter: {}
    }
  },
  computed: {
    ...mapGetters({
      deviceInfo: 'device'
    }),
    dataFields () {
      return this.fields && this.fields.filter((field) => {
        return field && field.filter
      })
    },
    isMobile () {
      return this.deviceInfo?.type === 'mobile'
    },
    isDesktop () {
      return this.deviceInfo?.type === 'desktop'
    },
    noCollapseFields () {
      return this.fields?.filter(i => !!i.noCollapse)
    },
    noCollapseCount () {
      return this.fields?.filter(i => !!i.noCollapse).length
    }
  },
  watch: {
    filter: {
      handler (n) {
        this.setRawFilter()
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    this.setRawFilter()
  },
  methods: {
    ...fields.methods,
    setRawFilter () {
      this.filter.forEach((item) => {
        this.rawFilter[item.column] = item.value
      })
    },
    onSubmit () {
      const data = []
      Object.keys(this.rawFilter)
        .filter((fieldKey) => {
          return !!this.rawFilter[fieldKey]
        })
        .forEach((fieldKey) => {
          const initial = this.filter.find(i => i.column === fieldKey)
          data.push({
            column: fieldKey,
            value: this.rawFilter[fieldKey],
            permanent: !!(initial && initial.permanent)
          })
        })
      this.$emit('submit', data)
    },
    getInputCls (key) {
      if (Object.keys(this.rawFilter).includes(key) && this.rawFilter[key] !== '') {
        return 'border-info text-info'
      }
      return 'border-muted text-muted'
    },
    getInputVariant (key) {
      if (Object.keys(this.rawFilter).includes(key)) {
        return 'info'
      }
      return 'muted'
    },
    getClearButtonVariant (key) {
      if (this.rawFilter[key] !== '') {
        return 'outline-danger'
      }
      return 'outline-secondary'
    },
    isNoCollapseField (key) {
      return this.fields
        .filter(i => i.noCollapse)
        .map(i => i.key)
        .includes(key)
    },
    isPermanentField (key) {
      return this.fields
        .filter(i => i.permanent)
        .map(i => i.key)
        .includes(key)
    },
    onClearItem (item) {
      const fieldKey = (item.column || item.key)
      const filter = {}
      if (this.isNoCollapseField(fieldKey) || this.isPermanentField(fieldKey)) {
        this.rawFilter[fieldKey] = ''
      } else {
        Object.keys(this.rawFilter)
          .filter(fieldName => fieldName !== (item.column || item.key))
          .forEach((fieldName) => {
            filter[fieldName] = this.rawFilter[fieldName]
          })
        this.rawFilter = filter
      }
      this.onSubmit()
    }
  }
}
</script>

<style lang="scss">
.filter-wrapper {
  .b-form-datepicker {
    &.text-info {
      .btn, .form-control  {
        color: var(--info) !important;
      }
    }
  }
}
</style>
