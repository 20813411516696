<template>
  <div
    class="data-layout"
    :style="`height: ${height}px;`"
  >
    <div
      ref="dh"
      class="d-flex align-items-center justify-content-between py-2"
    >
      <div class="flex-grow-1 mr-1 d-inline-flex align-items-center justify-content-between">
        <pagination
          v-if="isMobile"
          ref="pager"
          :limit="pageSize"
          :items="items"
          @page-changed="onPageChanged"
          @size-changed="onPageSizeChanged"
        />
        <data-sort
          v-if="sortFieldsCount > 0"
          ref="sort"
          :fields="fields"
          :order="order"
          :def="defaultOrder"
          :class="{'small': isMobile}"
          @on-sort="onSort"
          @on-shown="onSortShown"
          @on-hidden="onSortHidden"
        />
        <slot name="custom-top" />
        <!--
        <data-search
          v-if="!isMobile && canSearch"
          v-model="searchState"
          :query="searchQuery"
          :list="searchList"
          :is-busy="searchBusy"
          :placeholder="searchPlaceholder"
          @change-query="onSearchQuery"
          @search="onSearch"
          @flush-results="onFlushSearchResults"
        >
          <template #autocomplete-item="d">
            <slot
              name="data-search-autocomplete-item"
              :data="{ items: d.items, isCustom: d.isCustom, onSearch: d.onSearch }"
            />
          </template>
        </data-search>
        -->
      </div>
      <div class="action-wrapper">
        <b-dropdown
          size="sm"
          no-caret
          dropright
        >
          <template #button-content>
            <font-awesome-icon :icon="['fas', 'gear']" />
          </template>
          <slot name="custom-actions" />
          <b-dropdown-item-button
            v-if="filterFields.length > 0"
            :variant="filterState ? 'primary' : 'outline-secondary'"
            @click="toggleFilter"
          >
            <font-awesome-icon
              :icon="['fas', 'filter']"
            />
            <span>{{ $t('eDoc_form_a_filter') }}</span>
          </b-dropdown-item-button>

          <b-dropdown-group
            v-if="!!cardCollapse && tableLayout === 'card'"
            :header="$t('eDt_lt_folding')"
          >
            <b-dropdown-item-button
              variant="secondary"
              @click="collapseCards"
            >
              <font-awesome-icon icon="chevron-up" />
              {{ $t('eDoc_form_a_collapse') }}
            </b-dropdown-item-button>
            <b-dropdown-item-button
              variant="secondary"
              @click="expandCards"
            >
              <font-awesome-icon icon="chevron-down" />
              {{ $t('eDoc_form_a_expand') }}
            </b-dropdown-item-button>
          </b-dropdown-group>

          <data-layout-switcher layout="dropdown" />
          <pagination-page-size
            v-if="isMobile"
            v-model="pageSize"
            layout="dropdown"
          />
        </b-dropdown>
      </div>
    </div>
    <data-filter
      v-if="filterFields"
      ref="df"
      v-model="filterState"
      :fields="filterFields"
      :filter="filter"
      :filter-data="filterData"
      @submit="onFilterSubmit"
      @on-shown="calcHeight"
      @on-hidden="calcHeight"
    />
    <data-table
      v-if="tableLayout === 'table'"
      ref="dt"
      :fields="fields"
      :filter="filter"
      :order="order"
      :search="search"
      :repo="repo"
      :parent-id="parentId"
      :page="currentPage"
      :limit="pageSize"
      :height="dtHeight"
      :tbody-tr-class="tbodyTrClass"
      class="text-nowrap"
      head-variant="dark"
      striped
      hover
      small
    >
      <template
        v-for="fld in fields"
        #[`cell(${fld.key})`]="{ data: { item, index, field, value } }"
      >
        <slot
          :name="`cell(${field.key})`"
          :data="{ item, index, field, value }"
        >
          <span :key="`${field.key}_${item.id}`">{{ value || getValue(item, field) }}</span>
        </slot>
      </template>
    </data-table>
    <data-cards
      v-else-if="tableLayout === 'card'"
      ref="dt"
      class="text-nowrap"
      :fields="fields"
      :filter="filter"
      :order="order"
      :search="search"
      :repo="repo"
      :parent-id="parentId"
      :page="currentPage"
      :limit="pageSize"
      :height="dtHeight"
      :set-item-variant="setItemVariant"
      :collapse="cardCollapse"
    >
      <template v-if="hasSlot('card(header)')" #card(header)="{ data: { item }}">
        <slot
          name="card(header)"
          :data="{ item }"
        />
      </template>
      <template v-if="hasSlot('card(actions)')" #card(actions)="{ data: { item } }">
        <slot
          name="card(actions)"
          :data="{ item }"
        />
      </template>
      <template
        v-for="fld in fields"
        #[`cell(${fld.key})`]="{ data: { item, index, field, value } }"
      >
        <slot
          :name="`cell(${field.key})`"
          :data="{ item, index, field, value }"
        >
          <span :key="`${field.key}_${item.id}`">{{ value || getValue(item, field) }}</span>
        </slot>
      </template>
      <template #pagination>
        <pagination
          v-if="!isMobile"
          ref="pager"
          :limit="pageSize"
          :items="items"
          class="py-2 border-top"
          @page-changed="onPageChanged"
          @size-changed="onPageSizeChanged"
        />
      </template>
    </data-cards>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DataLayout',
  props: {
    repo: {
      type: String,
      default: ''
    },
    parentId: {
      type: [String, Number, null],
      default: () => null
    },
    fields: {
      type: Array,
      default: () => ([])
    },
    filterFields: {
      type: Array,
      default: () => ([])
    },
    filter: {
      type: Array,
      default: () => ([])
    },
    order: {
      type: Object,
      default: () => ({})
    },
    search: {
      type: String,
      default: ''
    },
    setItemVariant: {
      type: [String, Function],
      default: ''
    },
    height: {
      type: Number,
      default: 500
    },
    delayedCall: {
      type: Boolean,
      default: false
    },
    limit: {
      type: [Boolean, Number, String],
      default: false
    },
    page: {
      type: Number,
      default: 1
    },
    cardCollapse: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      currentPage: this.page,
      pageSize: (typeof this.limit === 'boolean' ? 10 : parseInt(this.limit, 10)) || 10,
      defaultOrder: this.order,
      filterState: false,
      dtHeight: this.height
    }
  },
  fetch () {
    if (this.$refs.dt) {
      this.$refs.dt.$fetch()
    }
    this.$nextTick(() => {
      this.calcHeight()
    })
  },
  computed: {
    ...mapGetters({
      getByName: 'repos/getByName',
      deviceInfo: 'device',
      tableLayout: 'tableLayout'
    }),
    isMobile () {
      return this.deviceInfo?.type === 'mobile'
    },
    items () {
      return this.getByName(`${this.repo}/index`)
    },
    filterData () {
      return this.getByName(`${this.repo}/filterData`)
    },
    hasFilterFields () {
      return this.filterFields.filter(f => f)
    },
    sortFieldsCount () {
      return this.fields?.filter(f => !!f.sortable).length || 0
    }
  },
  watch: {
    filter: {
      handler () {
        this.calcHeight()
      },
      deep: true
    },
    filterState () {
      this.calcHeight()
    },
    height () {
      this.calcHeight()
    }
  },
  methods: {
    onFilterSubmit (filter) {
      this.calcHeight()
      this.$emit('on-filter-submit', filter)
    },
    toggleFilter () {
      this.filterState = !this.filterState
      if (this.isMobile && this.filterState) {
        // if (this.searchState) {
        //   this.hideSearch()
        // }
      }
    },
    hideFilter () {
      this.filterState = false
    },
    onSortShown () {
      this.$nextTick(() => {
        this.calcHeight()
      })
    },
    onSortHidden () {
      this.$nextTick(() => {
        this.calcHeight()
      })
    },
    onSort (order) {
      if (this.order.by !== order.by || this.order.dir !== order.dir) {
        this.$emit('on-sort', order)
      }
    },
    tbodyTrClass (item) {
      return typeof this.setItemVariant === 'function'
        ? this.setItemVariant(item)
        : this.setItemVariant
    },
    getValue (item, field) {
      if (typeof field.formatter === 'function') {
        return field.formatter(item[field.key], field.key, item)
      } else {
        return item[field.key]
      }
    },
    hasSlot (name) {
      return !!this.$parent.$slots[name] ||
        !!this.$parent.$scopedSlots[name] ||
        !!this.$slots[name] ||
        !!this.$scopedSlots[name]
    },
    calcHeight () {
      this.$nextTick(() => {
        let height = this.height
        if (this.$refs.dh) {
          height -= this.$refs.dh?.offsetHeight || 0
        }
        if (this.$refs.df) {
          height -= this.$refs.df.$el?.offsetHeight || 0
          if (this.filterState) {
            height -= 12
          }
        }
        this.dtHeight = height
      })
    },
    onPageChanged (page) {
      this.currentPage = page
      if (this.$refs.dt) {
        this.$refs.dt.$el.scrollTo({ top: 0 })
      }
    },
    onPageSizeChanged (size) {
      this.pageSize = parseInt(size, 10)
    },
    collapseCards () {
      if (this.$refs.dt) {
        this.$refs.dt.collapseCards()
      }
    },
    expandCards () {
      if (this.$refs.dt) {
        this.$refs.dt.expandCards()
      }
    }
  }
}
</script>

<style scoped>

</style>
